


body {
  margin: 0;
  font-family: 'Oxygen', sans-serif;
  position: relative;
  overflow-x: hidden;
}

section {
  padding: 90px 15px;
  margin: 0;
}

.content {
  max-width: 1200px;
  display: block;
  margin: 0 auto;
}

p {
  font-size: 18px;
  margin: 5px 0 10px;
}

.bold {
  font-weight: 700;
}

.orange {
  color: #FF5100;
}

h2 {
  font-size: 36px;
  margin: 5px 0 10px;
}

@media screen and (min-width: 768px) {
  h2 {
    font-size: 70px;
    color: #848484;
    font-weight: 700;
  }
}

img {
  max-width: 100%;
  height: auto;
}

.center {
  text-align: center;
}

/* header */

.site-header {
  z-index: 10;
  font-family: 'Roboto Condensed', sans-serif;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background-color: black;
  padding: 0;
  align-items: center;
}

.site-header .nav-

{
  -ms-flex-item-align: start;
      align-self: flex-start;
  padding: 5px 5px 5px;
}

.site-header .nav-logo a img {
  max-width: 100px;
  width: 80px;
  height: 60px;
  padding: 10px 10px 0 10px
}
.site-header .nav-logo a span{
  display: block;
  font-size: 12px;
  color: white;
  text-decoration: none;
  text-align: center;
 
}

@media screen and (min-width: 600px) {
  .site-header .nav-logo a img {
    max-width: 314px;
    height: auto;
  }
}


@media screen and (min-width: 1100px) {
  .site-header {
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
  }
}

.text{
  font-size: 14px;
  font-weight: 500;
  color: white;
}

.site-header .nav-links {
  display: none;
  width: 100%;
  /* background-color: #DEF0FF; */
}

@media screen and (min-width: 1250px) {
  .site-header .nav-links {
    display: block;
    /* background-color: #FFF; */
  }
}

.site-header .nav-links ul {
  padding: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}

@media screen and (min-width: 1250px) {
  .site-header .nav-links ul {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
  }
}

.site-header .nav-links ul li {
  list-style: none;
}

.site-header .nav-links ul li a {
  font-size: 18px;
  text-transform: uppercase;
  font-family: 400;
  color: #7f53ac;
  letter-spacing: 1.2px;
  padding: 10px 15px;
  text-decoration: none;
  display: block;
}

.site-header .nav-links ul li a:hover{
  background-color: #efe0ff;
  border-radius: 2px;
}


.site-header button {
  cursor: pointer;
  background: transparent;
  border: none;
  max-width: 50px;
  display: block;
  position: absolute;
  top: 10px;
  right: 10px;
}

@media screen and (min-width: 768px) {
  .site-header button {
    top: 20px;
  }
}

@media screen and (min-width: 1250px) {
  .site-header button {
    display: none;
  }
}

.nav-links.active {
  display: block;
}

/* 
banner */

.banner {
  position: relative;
  height: 500px;
  text-align: center;
  color: #FFFFFF;
  padding: 90px 15px;
}

.banner-video {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  z-index: -1;
}

.banner-video video {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 100%;
  min-height: 100%;
}

/* .banner-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  z-index:  1;
  color: #fff;
} */

.banner .banner-text h1 {
  color: rgba(62, 53, 53, 0.7);
  text-transform: uppercase;
  font-family: 'Oxygen', sans-serif;
  font-weight: 1000;
  font-size: 36px;
  margin: 0;
  text-align: center;
}

.banner-text p {
  font-size: 1.5rem;
  margin-bottom: 30px;
}
@media screen and (max-width: 601px) {
  .banner .banner-text h1{
    font-size: 20px;
  }
}
@media screen and (min-width: 600px) {
  .banner .banner-text h1 {
    font-size: 45px;
  }
}
/* @media screen and (max-width: 600px) {
  .banner .banner-text h1 {
    font-size: 5px;
  }
}

.home .banner--content .tagline {
  font-size: 10px;
} */

/* @media screen and (max-width: 768px) {
  .banner .banner-text p {
    font-size: 20px;
  }
}
@media screen and (min-width: 929px) {
  .banner .banner-text p {
    font-size: 10px;
  }
} */
@media screen and (max-width: 601px) {
  .banner .banner-text p {
    font-size: 15px;
    text-align: justify;
  }
}
@media screen and (min-width: 600px) {
  .banner .banner-text p {
    font-size: 20px;
    text-align: justify;
  }
}

.home-bg-video{
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: -1;
}

.home {
  padding: 90px 15px;
  /* background-color: #923cb5;
  background-image: linear-gradient(147deg, #923cb5 0%, #000000 74%); */
  text-align: center;
  color: #FFFFFF;
}

.home.banner--content {
  max-width: 1400px;
  height: auto;
  display: block;
  margin: 0 auto;

  background-repeat: no-repeat;
  background-position: top right;
  background-size: contain;
}

.home.banner--content .name {
  text-transform: uppercase;
}

.home.banner--content .designation {
  color: rgba(255, 255, 255, 0.7);
  text-transform: uppercase;
  font-family: 'Oxygen', sans-serif;
  font-weight: 700;
  font-size: 36px;
  margin: 0;
}

/* @media screen and (min-width: 768px) {
  .home .banner--content .designation {
    font-size: 70px;
  }
} */

.home .banner--content .tagline {
  font-size: 18px;
}

/* @media screen and (min-width: 768px) {
  .banner .banner--content .tagline {
    font-size: 26px;
  }
} */


/* about */

.promo {
  background-color: black;
}

.promo .content h2 {
  font-size: 36px;
  font-weight: 300;
  color: #8c8282;
  text-shadow: 0 1px 2px #222;
  text-align: center;
  margin-top: 1px;
 
}
.promo .content h3{
  font-size: 36px;
  font-weight: 300;
  /* color: #8c8282; */
  text-shadow: 0 1px 2px #222;
  text-align: center;
  margin-top: 1px;
  /* color: #FFF; */
  text-decoration: none;
  text-transform: uppercase;
  /* font-weight: 700; */
  letter-spacing: 1.3px;
  -webkit-transition: all 0.8s ease-in-out;
  transition: all 0.8s ease-in-out;
 
  background: linear-gradient(92.12deg, #E548FF 2.22%, #2E4FFF 51.79%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    color: transparent;
    background-clip: text;
  
}
.promo .content h6{
  font-size: 18px;
  color: #E0E0E0;
  text-align: center;
  margin:0;
  margin-left: 5px;
  display: block;
  
  text-decoration: none;

}

@media screen and (min-width: 768px) {
  .promo .content h2 {
    font-size: 70px;
  }
}

/* .promo .content h2 span.orange {
  font-weight: 700;
} */

.promo .content p {
  font-size: 24px;
  color: #E0E0E0;
}

@media screen and (min-width: 768px) {
  .promo .content p {
    font-size: 36px;
  }
} 


#about .content h2 {
  text-align: center;
  font-size: 36px;
  font-weight: 300;
  color: #222;
  
  text-shadow: 0 1px 2px #222;
  
    font-size: 1.5em;
    margin-block-start: 0.83em;
    margin-block-end: 0.83em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
    unicode-bidi: isolate;
}

#about .about-container {
  display: block;
}

@media screen and (min-width: 768px) {
  #about .about-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
  }
}

#about .about-container-left {
  -ms-flex-preferred-size: 40%;
      flex-basis: 40%;
  /* background: #DEF0FF; */
  background-color: black;
  padding: 30px 15px;
  color: white;
  box-shadow: 1px 1px 1px 1px gold;
}

#about .about-container-left li {
  font-style: italic;
  line-height: 1.9;
  color: white;
}
#about .about-container-left p{
  font-size: 20px;
  color: #E0E0E0;
  text-align: justify;
  margin:0;
  margin-left: 5px;
  display: block;
  
  text-decoration: none
}

#about .about-container-right {
  -ms-flex-preferred-size: 40%;
      flex-basis: 40%;
  /* background: #F2F3F8; */
  background-color: black;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      box-shadow: 1px 1px 1px 1px gold;
}

#about .about-container-right img {
  max-width: 100%;
  -ms-flex-item-align: start;
      align-self: flex-start;
}

@media screen and (min-width: 1200px) {
  #about .about-container-right img {
    -ms-flex-item-align: stretch;
        -ms-grid-row-align: stretch;
        align-self: stretch;
  }
}

/* skill */
#skill {
  background-color: #7f53ac;
  background-image: linear-gradient(315deg, #7f53ac 0%, #647dee 74%);
}

#skill .skill--content {
  display: block;
}

@media screen and (min-width: 768px) {
  #skill .skill--content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
}

#skill .skill--content .left {
  -ms-flex-preferred-size: 30%;
      flex-basis: 30%;
}

#skill .skill--content .left h2 {
  font-size: 36px;
  color: #222;
}

#skill .skill--content .right {
  -ms-flex-preferred-size: 65%;
      flex-basis: 65%;
  display: block;
}

@media screen and (min-width: 768px) {
  #skill .skill--content .right {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
  }
}

#skill .skill--content .right .item {
  -ms-flex-preferred-size: 32%;
      flex-basis: 32%;
  text-align: center;
  border: 1px solid #222;
  padding: 30px 15px;
  margin-bottom: 30px;
  border-width: 5px 5px 5px 5px;
  -o-border-image: linear-gradient(45deg, #ff9800 0%, purple 100%);
     border-image: linear-gradient(45deg, #ff9800 0%, purple 100%);
  border-image-slice: 9;
  border-style: solid;
}

@media screen and (min-width: 768px) {
  #skill .skill--content .right .item {
    margin-bottom: 0;
  }
}

#skill .skill--content .right .item img {
  max-width: 100px;
  height: auto;
}


/* business */

#service h2 {
  max-width: 1200px;
  display: block;
  margin: 0 auto 20px auto;
  text-align: center;
  color: #282727;
}

#service h4{
  font-size: 36px;
  color: #222;
  -ms-flex-preferred-size: 30%;
      flex-basis: 30%;
      margin: 0 auto 0 30px;
      display: block;
      text-align: center;
      background: linear-gradient(92.12deg, #9e2ab3 2.22%, #081e98 51.79%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      color: transparent;
      background-clip: text;


}
#ser-sp{
  font-size: 22px;
  color: #222;
  -ms-flex-preferred-size: 30%;
      flex-basis: 30%;
      margin: 10px auto 80px 30px;
      display: block;
      text-align: center;
      color: #090909;
      font-family: Georgia, 'Times New Roman', Times, serif;
      font-weight: 500;
      font: bolder;
}

#service .service--content {
  display: block;
}

@media screen and (min-width: 768px) {
  #service .service--content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
  }
}

#service .service--content .item {
  -ms-flex-preferred-size: 32%;
      flex-basis: 32%;
  text-align: center;
  border: 1px solid #E9EBEC;
  padding: 20px 10px 40px;
  margin-bottom: 30px;
  margin-top: 30px;
  -webkit-transition: background-color 0.8s ease-in;
  transition: background-color 0.8s ease-in;
}

@media screen and (min-width: 768px) {
  #service .service--content .item {
    margin-bottom: 0;
  }
}

#service .service--content .item h3 {
  text-transform: uppercase;
  font-weight: 700;
}

#service .service--content .item img {
  max-width:60%;
  display: block;
  margin: 0 auto;
}

#service .service--content .item p {
  font-style: italic;
  display: block;
  margin-bottom: 30px;
  text-shadow: 0 1px 2px #222;
}

#service .service--content .item a {
  border: 1px solid #7f53ac;
  border-radius: 4px;
  padding: 10px 30px;
  color: #FFF;
  text-decoration: none;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 1.3px;
  -webkit-transition: all 0.8s ease-in-out;
  transition: all 0.8s ease-in-out;
  background-color: #9921e8;
  background-image: linear-gradient(315deg, #9921e8 0%, #5f72be 74%);
}

#service .service--content .item a:hover {
  background-color: #7f5a83;
  background-image: linear-gradient(315deg, #7f5a83 0%, #0d324d 74%);
  border: #0d324d;
}

#service .item:hover {
  background-color:#DEF0FF;
}
#service{
  background-image:linear-gradient(315deg, #0f100d 0%, rosybrown 74%);;
}


/* acheivements */

#glyImg {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
#glySec {
  /* min-height: 100vh;
  width: 100%; */
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #393737;

  /* margin: 100px 0; */
}
.glyContainer {
  background-color: white;
  width: 90%;
  max-width: 900px;
  /* margin: 0 auto; */
  box-shadow: 0px 0px 61px 7px #5e849c40;
  background-image: linear-gradient(315deg, #7f53ac 0%, #647dee 74%);
  /* padding: 50px 20px; */
  /* border-radius: 20px; */
  
}
/* .glyHeader {
  display: flex;
  flex-direction: column;
  align-items: center;
} */
.glyHeader h1 {
  font-size: 40px;
  font-weight: 900;
  letter-spacing: -2px;
  text-align: center;
  display: block;
  align-items: center;
  margin: 10px auto 80px 30px;
  font-family: sans-serif;
}
form {
  margin-top: 30px;
  width: 80%;
  max-width: 300px;
  position: relative;
}
input {
  border: none;
  border-bottom: 2px solid rgba(128, 128, 128, 0.479);
  font-size: 20px;
  width: 100%;
  outline: none;
  color: gray;
  background-color: transparent;
}
input::placeholder {
  opacity: 0.5;
  color: gray;
  font-family: "Montserrat";
}
form ion-icon {
  position: absolute;
  right: 0;
  font-size: 20px;
  opacity: 0.5;
  color: gray;
}
.gallery {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  margin-top: 50px;
  grid-gap: 20px;
}
.gltItem {
  width: 100%;
  height: 300px;
  position: relative;
  overflow: hidden;
  border-radius: 8px;
  transition: 0.3s ease box-shadow;
}

.gltItem:hover {
  box-shadow: 0px 0px 20px 3px #00000042;
}
.gltItem:nth-last-child(1) {
  margin-bottom: 0;
}
.gltItem h3 {
  width: 100%;
  padding: 20px;
  position: absolute;
  background-color: #00000040;
  color: aliceblue;
  bottom: -70px;
  font-weight: 300;
  transition: 0.5s ease-in-out bottom;
}
.gltItem:hover h3 {
  bottom: 0;
}
@media only screen and (min-width: 900px) {
  .glyContainer {
    max-width: 1160px;
    padding: 100px;
  }
  .glyHeader {
    flex-direction: row;
    justify-content: space-between;
  }
  .glyHeader h1 {
    font-size: 50px;
  }
  .glyHeader input {
    font-size: 24px;
  }
  .glyHeader ion-icon {
    font-size: 24px;
  }
  .gallery {
    grid-gap: 20px;
  }
}




/* testimonials */

#testimonial h2 {
  max-width: 1200px;
  display: block;
  margin: 0 auto 30px auto;
}

#testimonial .testimonial--content {
  display: block;
}

@media screen and (min-width: 768px) {
  #testimonial .testimonial--content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
  }
}

#testimonial .testimonial--content .item {
  -ms-flex-preferred-size: 45%;
      flex-basis: 45%;
  margin: 15px 0;
}

blockquote {
  margin: 0;
}

blockquote p {
  padding: 15px;
  background: #DEF0FF;
  border-radius: 5px;
}

blockquote p::before {
  content: '\201C';
}

blockquote p::after {
  content: '\201D';
}

/* pricing */
/* #pricing {
  background-color: #DEF0FF;
} */

#pricing .pricing--content {
  display: block;
  max-width: 1600px;
  margin: 0 auto;
}

@media screen and (min-width: 768px) {
  #pricing .pricing--content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
  }
}

#pricing .pricing--content .item {
  -ms-flex-preferred-size: 32%;
      flex-basis: 32%;
  border: 1px solid #848484;
  border-radius: 3px;
  padding: 20px 10px 30px;
  margin-bottom: 30px;
}

@media screen and (min-width: 768px) {
  #pricing .pricing--content .item {
    margin-bottom: 0;
  }
}

#pricing .pricing--content .item .price {
  font-size: 36px;
  color: #cb2027;
  font-weight: 700;
}

#pricing .pricing--content .item img {
  max-width: 200px;
  height: auto;
  display: block;
  margin: 0 auto;
}

#pricing .pricing--content .item .description {
  font-style: italic;
  min-height: 60px;
  display: block;
  margin: 20px 0;
}

#pricing .pricing--content .item a {
  padding: 10px 20px;
  font-weight: 700;
  text-transform: uppercase;
  border: 1px solid #222;
  text-decoration: none;
  color: #FFF;
  background: #222;
}

/* contact */
#contact{
 
    background-color: #DEF0FF;
  
}
#contact .contact-detail, #contact address {
  max-width: 800px;
  display: flex;
  
  margin: 0 auto;
}
#contact span{
  font-size: 22px;
  margin-top: 20px;
  text-align: center;
  font-weight: 700;
  margin-left: 5px;
  display: block;
  
  background: linear-gradient(92.12deg, #E548FF 2.22%, #2E4FFF 51.79%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    color: transparent;
    background-clip: text;
  text-decoration: none;
}

#contact address {
  margin-top: 60px;
  display: flex;
}

#contact address a {
  text-decoration: none;
}

/* socialmedia */
.social-share {
  display: none;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  position: fixed;
  top: 50vh;
  left: 0;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

@media screen and (min-width: 768px) {
  .social-share {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
}

.social-share a {
  width: 50px;
  height: 50px;
  background-color: #DEF0FF;
  border: 1px solid #7f53ac;
  -webkit-transition: background-color 0.8s ease-in;
  transition: background-color 0.8s ease-in;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.social-share a img {
  width: 30px;
  height: auto;
  -ms-flex-item-align: center;
      -ms-grid-row-align: center;
      align-self: center;
}

.social-share a:hover {
  background-color: #7f53ac;
}
#ig{
  width: 30px;
  height: auto;
  -ms-flex-item-align: center;
      -ms-grid-row-align: center;
      align-self: center;
      color: rgb(87.843138%,25.490198%,23.921569%);
      fill-opacity:1;
      stroke:none;fill-rule:nonzero
}
/* footer */





footer{
  color: #fff;
  /* background-image: linear-gradient(90deg, rgba(39,27,111,1) 0%, rgba(87,25,45,1) 100%); */
 background-color: #000000;
  position: relative;
  width: 100%;
  height: 100%;
  /* top:350px; */
  /* border-top-left-radius: 70px; */
  font-size: 0.8rem;
  z-index: -1;
}

@media (max-width:960px) {
  footer{
    width: 100%;
    top: 120px;
  }
  

  
.logo{
  
    max-width: 100px;
    width: 50px;
    height: 50px;
    padding: 8px 8px 0 8px
  }
  
.office{
    grid-column: 3/5;
  }

.link{
    grid-column: 1/3;
  }
  
	
}

@media (max-width:660px){
  .main{
    gap:10px;
    margin: 0;

  }
  
  .icons{
    display: flex;
  }
  
 
  
  .row{
    grid-column: 1/4;
  }
}

.abdullah{
  width: 100px;
  max-width: 100px;

  height: 100px;
  padding: 8px 8px 0 8px
}

.main{
  
  margin: 50px;
  padding-top: 20px;
  padding-bottom: 50px;
  display: grid;
  gap: 50px;
  grid-template-columns: repeat(4, 1fr );
}

.row{
 margin-top: 50px;
}

.footer-header{
  font-size: 1.5rem;
  /* margin-bottom: 20px; */
}

.office-des{
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.office a{
  text-decoration: none;
  color: #98A8F8;
}

.office a:hover{
  color: #fff;
}

.num{
  font-size: 1rem;
  font-weight: 500;
}

.link-des{
  display: flex;
  flex-direction: column;
  gap:10px;
}

.link-des a{
  color: white;
  text-decoration: none;
  font-size: 1rem;
}

.link-des a:hover{
  color: #7f53ac;
  
  transition: linear 0.3s;
}
.logo-des p{
  font-size: 10px;
  margin-top: 0;
  padding-left: 0;
  padding-top: 0%;
  margin-left: -1;
  display: block;
 
  
}

.btn-know{
  border: 1px solid #7f53ac;
  margin-top: 8px;
  margin-left: 8px;
  position: absolute;
  border-radius: 10px;
  padding: 10px 10px;
  color: #FFF;
  text-decoration: none;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 1.3px;
  -webkit-transition: all 0.8s ease-in-out;
  transition: all 0.8s ease-in-out;
  background-color: #9921e8;
  background-image: linear-gradient(315deg, #9921e8 0%, #5f72be 74%);
}

.btn-know:hover{
  background-color: #7f5a83;
  background-image: linear-gradient(315deg, #7f5a83 0%, #0d324d 74%);
  border: #0d324d;
}


.subscribe{
  display: flex;
}

input[type=mail]{
  border: none;
  padding-bottom: 5px;
  border-bottom: 1px solid #fff;
  background-color: transparent;
  font-size: 0.8rem;
}

.sub-icon{
  margin: 7px;
  margin-right: 15px;
  margin-left: 0;
  font-size: 1.5rem;
}

.ri-arrow-right-line{
  margin-left: 0;
  
}

.icons a{
  
  text-decoration: none;
  color: #fff;
  /* border: 1px solid #fff; */
  justify-content: center;
  padding: 10px;
  border-radius: 50%;
  align-items: center;
  font-size: 1.1rem;
  margin: 2%;
}
.icons a img{
  width: 25px;
  height: 25px;
  display: block;
  align-items: center;
  margin-left: 40px;
  text-align: center;
 
}
#ft-ig{
  width: 25px;
  height: 25px;
  display: block;
  align-items: center;
  margin-left: 40px;
  height: auto;
  -ms-flex-item-align: center;
      -ms-grid-row-align: center;
      align-self: center;
      color: rgb(87.843138%,25.490198%,23.921569%);
      fill-opacity:1;
      stroke:none;fill-rule:nonzero
}
#ft-ig:hover{
  background-color: #7f53ac;
  border-radius: 2px;
}
.icons a img:hover{
background-color: #7f53ac;
border-radius: 2px;
}


/* .social-icon{
  position: relative;
  top:2px;
} */



.newsletter-des{
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.copyright{
  text-align: center;
}

hr{
  width: 90%;
  margin: 2px auto;
  opacity: 40%;
  border: 0.01px solid white;
}

.copyright p{
  padding: 20px;
}
